/* eslint-disable jsx-a11y/heading-has-content*/
import React from "react";
import Logo from "./images/Logo.svg";

export default props => {
  return (
    <div className="is-white-bg pad-5-lr pad-3-tb">
      <div className="row flex padding-0-tb container">
        <div className="col-xs-4 flex">
          <a
            href="https://sld.codes/"
            title="home"
            className=" align-horizontal is-white flex grow-on-hover"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={Logo}
              alt=""
              style={{ paddingBottom: 2, height: "21.5px" }}
            />
          </a>
        </div>
      </div>
    </div>
  );
};
