import React, { useState } from "react";
import Header from "./header";
import "./styles/global.scss";
import Stepper from "react-js-stepper";
import { createCreds, validateCreds } from "./webauthn";

const steps = [{ title: "1" }, { title: "2" }, { title: "3" }];

const App = () => {
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [bioEnabled, setBioEnabled] = useState(false);

  const nextStep = () => setStep(step + 1);
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="row pad-5 ">
            <div className="col-xs-12">
              <h3 className="margin-2-tb">LOGIN</h3>
              <div
                className="line is-light-grey margin-2-b"
                style={{ maxWidth: "100%" }}
              />
              <p className="margin-2-tb" style={{ fontSize: 14 }}>
                (This is a demo - any email and password will do)
              </p>
            </div>
            <div className="col-xs-12">
              <input
                type="text"
                name="email"
                placeholder="Enter your email"
                value={email}
                className="input pad-3-tb "
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="col-xs-12">
              <input
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                className="input pad-3-tb "
                onChange={e => setPassword(e.target.value)}
              />
            </div>
            <div className="col-xs-12">
              <button
                className="btn margin-5-t"
                style={{ width: "100%" }}
                onClick={() => nextStep()}
              >
                Login
              </button>
            </div>
            {bioEnabled && (
              <div className="col-xs-12">
                <button
                  className="btn-variant margin-5-t"
                  onClick={() => validateCreds(() => setStep(3))}
                  style={{ width: "100%" }}
                >
                  Biometric Login
                </button>
              </div>
            )}
          </div>
        );

      case 2:
        return (
          <div className="row pad-5 ">
            <div className="col-xs-12">
              <h3 className="margin-2-tb">
                Want to login with biometrics next time?
              </h3>
              <div
                className="line is-light-grey margin-2-b"
                style={{ maxWidth: "100%" }}
              />
            </div>

            <div className="col-xs-12">
              <button
                className="btn margin-5-t"
                onClick={() =>
                  createCreds(() => {
                    nextStep();
                    setBioEnabled(true);
                  })
                }
              >
                Yes Please
              </button>
            </div>
            <div className="col-xs-12">
              <button
                className="btn-variant margin-5-t"
                onClick={() => nextStep()}
              >
                No Thanks
              </button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="row pad-5 ">
            <div className="col-xs-12">
              <h1 className="margin-2-tb">🎉</h1>
              <div
                className="line is-light-grey margin-2-b"
                style={{ maxWidth: "100%" }}
              />
              <h3 className="margin-2-tb">You're logged in!</h3>
            </div>
            <div className="col-xs-12">
              <button className="btn margin-5-t" onClick={() => setStep(1)}>
                Logout
              </button>
            </div>
          </div>
        );
    }
  };
  return (
    <div
      className="is-pink-bg is-white text-align-center pad-20-b"
      style={{ minHeight: "100vh" }}
    >
      <Header />
      <div className="margin-5-tb">
        <h1 className="is-hero-menu margin-1-b">WebAuthn Demo</h1>
        <h4 className="margin-0">By SLD</h4>
      </div>
      <Stepper
        steps={steps}
        activeStep={step}
        onSelect={() => {}}
        showNumber={false}
      />
      <div
        className="is-white-bg is-dark-blue border-radius margin-10-tb"
        style={{
          maxWidth: 400,
          width: "90vw",
          minHeight: "50vh",
          marginRight: "auto",
          marginLeft: "auto"
        }}
      >
        {renderStep()}
      </div>
    </div>
  );
};

export default App;
